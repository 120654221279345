<script lang="ts" setup>
import { MediaImageFragment, ParagraphTeaserTileItem, ParagraphTeaserTiles } from '~/api/graphql/generated'

const props = defineProps<{
  entity: ParagraphTeaserTiles
}>()

const title = computed(() => props.entity.fieldTitle || '')
const subTitle = computed(() => props.entity.fieldIntroduction?.processed || '')
const image = computed(() => {
  const small = props.entity?.fieldMobileImage?.entity as MediaImageFragment
  const large = props.entity?.fieldImage?.entity as MediaImageFragment
  return small || large
    ? {
        ...(small ? { small } : { small: large }),
        ...(large ? { large } : { large: small }),
      }
    : undefined
})
const items = props.entity.fieldItems?.map((item) => {
  return {
    title: (item?.entity as ParagraphTeaserTileItem).fieldTitle,
    link: {
      label: (item?.entity as ParagraphTeaserTileItem).fieldLink?.title || '',
      url: (item?.entity as ParagraphTeaserTileItem).fieldLink?.url?.path || '',
      target: (item?.entity as ParagraphTeaserTileItem).fieldLink?.options?.attributes?.target || '_self',
    },
  }
})
</script>

<template>
  <SectionSpecial :title="title" :sub-title="subTitle" :image="image">
    <ul v-animate="{ cluster: true }" class="items cul">
      <li v-for="(item, index) in items" :key="index" v-animate="{ animation: 'vertical-reveal' }">
        <h4>{{ item.title }}</h4>
        <NuxtLink class="text-small link-default" :to="item.link?.url" :target="item.link?.target">{{
          item.link.label
        }}</NuxtLink>
      </li>
    </ul>
  </SectionSpecial>
</template>
<style scoped lang="scss">
.items {
  display: grid;
  @include breakpoint('ts') {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
  @include breakpoint('tl') {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
  gap: 12px;
  li {
    padding: 32px 40px;
    background-color: var(--c-background-medium);
  }
}
</style>
